import Vue from 'vue';
import Router from 'vue-router';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            }
        },

        {
            path: '/uitleg',
            name: 'Uitleg',
            component: () =>
            {
                return import( /* webpackChunkName: "uitleg" */ '@/app/pages/Uitleg' );
            }
        },

        {
            path: '/game',
            name: 'Game',
            component: () =>
            {
                return import( /* webpackChunkName: "game" */ '@/app/pages/Game' );
            }
        },

        {
            path: '/score',
            name: 'Score',
            component: () =>
            {
                return import( /* webpackChunkName: "score" */ '@/app/pages/Score' );
            }
        },

        {
            path: '/result',
            name: 'Result',
            component: () =>
            {
                return import( /* webpackChunkName: "result" */ '@/app/pages/Result' );
            }
        },

        {
            path: '/feedback',
            name: 'Feedback',
            component: () =>
            {
                return import( /* webpackChunkName: "feedback" */ '@/app/pages/Feedback' );
            }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
